import { SET_LOCALE } from "../actions";
const initialState = {
  lang: "en",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, lang: action.payload };

    default:
      return state;
  }
}

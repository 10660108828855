import React from "react";
import { connect } from "react-redux";
import { FormattedMessage,FormattedHTMLMessage } from "react-intl";
import { Field, reduxForm } from "redux-form";
import axios from "axios";
import { ROOT_URL, PAYPAL_KEY } from "../../helper/Config";
import { Link } from "react-router-dom";
import $ from "jquery";
import { history } from "../../helper/History";
import { PayPalButton } from "react-paypal-button-v2";
import { updaetPaymentData } from "../../actions/Payment";


//import AddressSection from "./AddressSection";
//import CardSection from "./CardSection";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      error: false,
      errorMsg: "",
      loading: false,
      tests: 0,
      testname: '',
      amount: 0,
      currency: 'EUR',
      sub:0,
      promo:"",
   
    };
    //this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    //console.log('Inside componentDidMount ');
    localStorage.removeItem("promos")
    if (this.props.isSignedIn) {
    
      // $("#sale").modal("hide");
    
      var userid = localStorage.getItem("userId");
      //console.log("user loggedIN "+userid);
      var api_url = `${ROOT_URL}/getTests/${userid}`;
      // get no of users test
      axios.get(api_url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }).then(response => {
		console.log('Inside  '+response.data.subscription);
        this.setState({
          remainingTest: response.data.tests
        });
        localStorage.setItem('yz',response.data.subscription)
        this.setState({
          sub: response.data.subscription
        });
       
      }).catch(error => {
        console.log(error);
      });
   
    }
    

  }
  componentDidUpdate(){

    if(localStorage.getItem('userRole')=='Hr'){

     
      console.log(localStorage.getItem('yz'));
      var f=localStorage.getItem('yz');
    if(f==='1'){

        
        const title    =  (this.props.lang === "en") ? "Sorry, you must buy first a subscription before add new credits." : "Désolé, vous devez d’abord acheter un abonnement avant d’ajouter de nouveaux crédits "; $('#exampleModal .modal-body').text(title);
        $("#exampleModal").modal("show");
        localStorage.removeItem("yz")
        history.push("/Subscribe");
    
      
    }
  }
  }

  // handleSubmit = ev => {
  //   ev.preventDefault();
  //   // User clicked submit
  //   this.setState({ error: false });
  //   let { tests, amount } = this.state;
  //   if (tests && tests > 0 && amount > 0) {
  //     const token = localStorage.getItem("token");
  //     const userName = localStorage.getItem("userName");
  //     this.setState({ loading: true });
  //     var userType = localStorage.getItem("role");

  //     // this.props.stripe
  //     //   .createToken({
  //     //     name: userName
  //     //   })
  //     //   .then(
  //     //     resp => {
  //     //       //console.log(' resp+> '+JSON.stringify(resp));
  //     //       if (resp.token) {
  //     //         var submitData = {
  //     //           stripeToken: resp.token.id,
  //     //           amount: amount,
  //     //           tests: tests,
  //     //           userRole: userType
  //     //         };
  //     //         try {
  //     //           this.props.makeCharge(token, submitData, response => {
  //     //             //console.log(' errors===> '+JSON.stringify(response));
  //     //             //console.log(' errorsCode '+JSON.stringify(response.response.status));
  //     //             if(response.response.status === 200){
  //     //               if (response.status === "succeeded") {
  //     //                 this.setState({ complete: true });
  //     //               } else {
  //     //                 this.setState({
  //     //                   error: true,
  //     //                   errorMsg: "Error occur during payment"
  //     //                 });
  //     //               }
  //     //               this.setState({ loading: false });
  //     //             }else{
  //     //               this.setState({
  //     //                 error: true,
  //     //                 errorMsg: response.response.status+" Error!"+response.response.data.message
  //     //               });
  //     //               this.setState({ loading: false });
  //     //             }
  //     //           });
  //     //         } catch (error) {
  //     //           //console.log(' errors++++>> '+JSON.stringify(error));
  //     //           this.setState({
  //     //             error: true,
  //     //             errorMsg: error.message
  //     //           });
  //     //           this.setState({ loading: false });
  //     //         }
  //     //       } else {
  //     //         var errorMessage = (resp.error.message !== '' ? resp.error.message :'Enter valid card details');
  //     //         this.setState({
  //     //           loading: false,
  //     //           error: true,
  //     //           errorMsg: errorMessage
  //     //         });
  //     //       }
  //     //     },
  //     //     err => {
  //     //       console.log(err);
  //     //     }
  //     //   );
  //   } else {
  //     this.setState({
  //       loading: false,
  //       error: true,
  //       errorMsg: "Select No. of tests"
  //     });
  //   }

  //   // const data = await {
  //   //   stripeToken: stripe_token.token.id,
  //   //   amount: 2
  //   // };
  //   // console.log(stripe_token);
  //   //await this.props.makeCharge(token, stripe_token);

  //   // let {stripe_token} = await this.props.stripe.createToken({
  //   //   name: "Manpreet"
  //   // });
  //   // let response = await fetch("/charge", {
  //   //   method: "POST",
  //   //   headers: { "Content-Type": "text/plain" },
  //   //   body: stripe_token.id
  //   // });

  //   // if (response.ok) this.setState({ complete: true });
  // };

  changeTestCount(event) {
    var data = event.target.value.split('~');
  //
  //   var datas=Number(data[1]);
  //
  // console.log((datas*0.2));
  // var vat=datas*0.2;
  // var addedvat=datas+vat
    console.log('Inside charge amount '+event.target.value+' 1st  '+data[0]+' 2nd  '+data[1]);
    this.setState({
      tests: data[0],
      amount: data[1],
      // vatammount:addedvat,
      testname: data[2]
    });
  }
  changeTestCount2(event) {
    var data = event.target.value.split('~');
  //
    var datas=Number(data[1]);
  //
  // console.log((datas*0.2));
  var vat=datas*0.2;
  // var vat=0
  var addedvat=datas+vat;
    console.log('Inside charge amount '+event.target.value+' 1st  '+data[0]+' 2nd  '+addedvat);
    this.setState({
      tests: data[0],
      amount: addedvat,
      // vatammount:addedvat,
      testname: data[2]
    });
    // const confirmBox = window.confirm(  "Your total amount including 20% vat for "+data[0]+" tests = "+addedvat+" "+this.state.currency);
  }
  updateCurrency(e) {
    //console.log('Currency val  '+e.currentTarget.value+' ID '+'Currency val  '+e.currentTarget.id );
    this.setState({
      currency: e.currentTarget.value
    });
  }


  renderLoading = () => {
    if (this.state.loading) {
      return (
        <div className="p-overlay">
          <div
            className="spinner-border m-5"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderError = () => {
    let { error, errorMsg } = this.state;
    if (error) return <h4 className="text-danger text-center">{errorMsg}</h4>;
    else return null;
  };

  renderSelectBox= (userType) => {
    var enterkilltest    =  (this.props.lang === "en") ? "2 HOURS INTERVIEW WITH AN EXPERT = €400 " : "2 HEURES AVEC UN EXPERT = 400 € ";
    var fullskilltest    =  (this.props.lang === "en") ? "YOUR FULL ASSESSMENT (4 tests + 2h with an expert) = €450" : "VOTRE BILAN EN LIGNE (4 tests + 2h avec un expert) = 450 €";
    var debreifingtest    =  (this.props.lang === "en") ? "YOUR CAREER POINT (2 tests + 1h with an expert) = €250" : "VOTRE POINT CARRIERE (2 tests + 1h avec un expert) = 250 € ";
    var val1   =  (this.props.lang === "en") ? "1 test = €20" : "1 test = 20 € ";
    var val2    =  (this.props.lang === "en") ? "2 tests (€19/test) = €38" : "2 tests (19€/test) = 38 € ";
    var val3    =  (this.props.lang === "en") ? "3 tests (€18/test) = €54" : "3 tests (18€/test) = 54 € ";
    var val4    =  (this.props.lang === "en") ? "4 tests (€17/test) = €68" : "4 tests (17€/test) = 68 € ";
    var professconsul =  (this.props.lang === "en") ? "1 hour online Debriefing/Consulting/Training" : "1 heure Formation/Conseil/Debriefing en ligne";
    if (userType === 'User') {
      return (
        // <select onChange={e => this.changeTestCount(e)} className="form-control fomt-control-lg"> used to manuplate the amount on changes
        <select onChange={e => this.changeTestCount(e)} className="form-control fomt-control-lg">
          <option>Please Select</option>
          <option value="4~450~FULL SKILLS ">{fullskilltest}</option>
          <option value="2~420~ENTREPRENEUR">{enterkilltest}</option>
          <option value="1~220~DEBREIFING">{debreifingtest }</option>
         
  
          <option value="1~20~1 Test">{val1}</option>
          <option value="2~38~2 Test">{val2}</option>
          <option value="3~54~3 Test">{val3}</option>
          <option value="4~68~4 Test">{val4}</option>
          
        </select>
      )
    } else {
      return (
      <select onChange={e => this.changeTestCount2(e)} className="form-control fomt-control-lg">
        <option>Please Select</option>
        {/* <option value="5~165~5 Test">5</option> */}
        <option value="10~299~10 Test">10</option>
        <option value="25~599~25 Test">25</option>
        <option value="50~999~50 Test">50</option>
        <option value="1~100~1">{professconsul}</option>
      </select>
      )
    }
  };

  promo(value){
    console.log(value)
    localStorage.removeItem("promos")
    this.setState({promo:value})
    console.log(this.state.promo)
    if(this.state.promo){
      localStorage.setItem("promos",this.state.promo)
    }
  }
  BLACKFRIDAY(value){
    console.log(value)
    localStorage.removeItem("promos")
    this.setState({promo:value})
    console.log(this.state.promo)
  }

  promoMessage(){
    // setTimeout(() => {
    //   this.props.clearMessage();
    // }, 80000);
    // check for signup error message
if(localStorage.getItem("promos")=="BLACKFRIDAY"){
      return (
        <div  class=" alert alert-success alert-dismissible fade show "  role="alert">
    <FormattedHTMLMessage id="promomessage"/>
        </div>
      );
      }
 

  }

  renderRadioField = ({ input, label, type, checked, id }) => (
    <div>
      <input {...input} type={type} checked={checked} id={id} />
      <label>{label}</label>
    </div>
  );

  renderPayPalButton= () => {
   
    let { tests,amount, currency,testname } = this.state;
    const token = localStorage.getItem("token");
    var userType = localStorage.getItem("role");
    var lang=localStorage.getItem('lang');
    console.log('tests => '+tests+' amount '+amount+'çurrency'+currency);
   

    if (amount > 0 ) {
      // if(localStorage.getItem("promos")&&localStorage.getItem("promos")=="BLACKFRIDAY" ){
      //  var  amounts=amount - (amount*0.50.toFixed(2))
      //  console.log(amounts)
      // }else{
        var amounts=amount;
        console.log(amounts)
      // }
// const confirmBox = window.confirm(  "Your total amount including 20% vat for "+tests+" tests = "+amount+" "+currency);
//     if(confirmBox===true){
      return (
        <PayPalButton
        amount= {amounts}
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        shippingPreference="NO_SHIPPING"
        options={{
            clientId: PAYPAL_KEY,
            currency: currency
        }}
        onError ={(err) => {
          console.log(' onError ===> '+JSON.stringify(err));
          this.setState({
            error: true,
            errorMsg: err
          });
          this.setState({ loading: false });
        }}
        catchError ={(err) => {
          console.log(' onError ===> '+JSON.stringify(err));
          this.setState({
            error: true,
            errorMsg: err
          });
          this.setState({ loading: false });
        }}
        onSuccess={(details, data) => {
          this.setState({ loading: true }); // loading start
          //alert("Transaction completed by " + details.payer.name.given_name+' -- '+data.orderID);
          //console.log(' onSuccess ===> '+JSON.stringify(data)+''+' details ===> '+JSON.stringify(details));
            console.log(data);
            try {
              var OrderData = {
                amount: amount,
                tests: tests,
                testname: testname,
                userRole: userType,
                orderId : data.orderID,
                lang:lang
              };
              // console.log(lang);
              this.props.updaetPaymentData(token, OrderData, response => {
                //console.log(' errorsCode '+JSON.stringify(response.response.status));
                if(response.status === 200){
                  localStorage.removeItem("promo")
                  //console.log(' status1 ==> '+response.status+' status1 '+response.data.status);
                  if (response.data.status === "succeeded") {
                    this.setState({ complete: true });
                    // updat the remaintest data in state by fething updated record
                    var userid = localStorage.getItem("userId");
                    //console.log("user loggedIN "+userid);
                    var api_url = `${ROOT_URL}/getTests/${userid}`;
                    // get no of users test
                    axios.get(api_url, {
                      headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json"
                      }
                    }).then(response => {
                     console.log('Update remainingTest  '+response.data.tests);
                     //remainingTest
                     $('#remainingTest').html('('+response.data.tests+')');
                      this.setState({
                        remainingTest: response.data.tests
                      });
                    }).catch(error => {
                      console.log(error);
                    });
                  } else {
                    this.setState({
                      error: true,
                      errorMsg: "Error occur during payment"
                    });
                  }
                  this.setState({ loading: false });
                }else{
                  this.setState({
                    error: true,
                    errorMsg: response.response.status+" Error!"+response.response.data.message
                  });
                  this.setState({ loading: false });
                }
              });
            } catch (error) {
              console.log(' errors++++>> '+JSON.stringify(error));
              this.setState({
                error: true,
                errorMsg: error.message
              });
              this.setState({ loading: false });
            }
          // OPTIONAL: Call your server to save the transaction
          // return fetch("/paypal-transaction-complete", {
          //   method: "post",
          //   body: JSON.stringify({
          //     orderID: data.orderID
          //   })
          // });
        }}
      />
      )
    // }
    }
  };

  render() {
    let { complete,tests,testname } = this.state;
    var userType = localStorage.getItem("userRole");
    //console.log('userType => '+userType);
    var redirectTO = (userType === 'User' ? '/individual' :'/hr');
    //if (complete) return <h4 className="text-success text-center"><FormattedMessage id="buycredit.message" defaultMessage="Thank you for purchase test, Now you can continue do your test"/></h4>;
    if (complete) return <div><h4 className="text-success text-center"><FormattedMessage id="buycredit.message" defaultMessage={`Thank you for your purchase {nooftest} no of test, Now you can continue and do your test `} values={{nooftest: testname}}/></h4><div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="choose_your_test_btn" defaultMessage="CHOOSE YOUR TEST "/></Link></div></div>;
    //var choose = <FormattedMessage id="buytest.choose" defaultMessage="Choose"/>
    //console.log(choose);
    return (
      <div className="card-div">
        <React.Fragment>{this.renderLoading()}</React.Fragment>
        {userType === 'User' ? (
          <h2 className="text-center mb-4"><FormattedHTMLMessage id="buytest.title.individual" defaultMessage="BUY TESTS FOR INDIVIDUAL"/></h2>
        ):(
          <h2 className="text-center mb-4"><FormattedHTMLMessage id="buytest.title.hr" defaultMessage="BUY TESTS FOR PROFESSIONAL"/></h2>
        )}
        {this.renderError()}
        {/* {this.promoMessage()} */}
        {/* <form onSubmit={this.handleSubmit}> */}
        <form>
  

          <div className="form-group">
            <div className="form-check form-check-inline">
              <Field name="currency" component={this.renderRadioField} type="radio"  checked={(this.state.currency !== 'USD' ? true : false)} value="EUR" label="EUR" id="EUR" onChange={e => this.updateCurrency(e)} />
              <Field name="currency" component={this.renderRadioField} type="radio" checked={(this.state.currency === 'USD' ? true : false)} value="USD" id="USD" label="USD" onChange={e => this.updateCurrency(e)} />
            </div>
          </div>
          <div className="form-group">
            <label className="font-weight-bold">
            {userType === 'User' ? (
            <FormattedMessage id="inbuytest.noof.creits" defaultMessage="No. of credits"/>):(
            <FormattedMessage id="hrbuytest.noof.creits" defaultMessage="No. of credits"/>)
            }
            </label>
              {this.renderSelectBox(userType)}
          </div>
          <div className="form-group">
          <label className="font-weight-bold">

          Promo Code &nbsp;
           </label>
           <input  type="text" value={this.state.promo} onChange={(e) =>this.BLACKFRIDAY(e.target.value) } onBlur={(e) => this.promo(e.target.value)}/>
           <input type="button" value="Apply" className="btn btn-sm btn-primary ml-1" /><br/>
           <FormattedMessage id="inbuytest.validity" defaultMessage="24h Validity "/>
           </div>

          {/* <div hidePostalCode={true} /> */}
          {/* <CardElement hidePostalCode={true} /> */}
          {/* <CardNumberElement />
        <CardExpiryElement />
        <CardCVCElement /> */}
          <div className="text-center">
          {this.state.sub!=1?this.renderPayPalButton():''}
              {/* <button className="btn btn-info my-3">
                <FormattedMessage id="buytest.button" defaultMessage="BUY CREDITS"/>
              </button> */}
              
              <img src="assets/images/stripe_secure.png" alt="First slide" className="cc_secure" />
          </div>
        </form>
      
        <div>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
     
      <div class="modal-body" style={{marginTop:'100px'}}>
        ...
      </div>
  
    </div>
  </div>
</div>
</div>
        <div className="buy-credits-detail">
          {userType === 'User' ? (
            <FormattedHTMLMessage id="ind.buy.test.detail" defaultMessage="<ul className='indi-ul text-center'> <li>Buy 1 test credit = <b>€ 15</b>/test</li><li>Buy 2 test credits = <b>€ 13,5</b>/test</li><li>Buy 3 test credits = <b>€ 12</b>/test</li><li>Buy 4 test credits and more = <b>€ 10</b>/test</li></ul>"/>
          ):(
            <FormattedHTMLMessage id="hr.buy.test.detail" defaultMessage="<ul className='indi-ul text-center'> <li>Buy 5 test credit = <b>€ 13,50</b>/test (-10%) €67,50</li><li>Buy 10 test credits = <b>€ 9,90</b>/test (-33%):€ 99</li><li>Buy 50 test credits = <b>€ 9</b>/test (-40%)   :€ 450</li></ul>"/>
            
          )}
           {userType === 'User' ? (
           <ul className='indi-ul font-weight-bold'> <li> <FormattedMessage id="inbuytest.nooftest" defaultMessage="Total no of remaining test you have "/>( {this.state.remainingTest} ) </li></ul> 
           ):(
            <ul className='indi-ul font-weight-bold'> <li> <FormattedMessage id="hrbuytest.nooftest" defaultMessage="Total no of remaining test you have "/>( {this.state.remainingTest} ) </li></ul> 

           )}
          
           {/* {userType === 'User' ? (
            <div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="ind.start.ur.test" defaultMessage="Start your test"/></Link></div>
          ):(
            <div className="col-sm-12 mx-auto text-center mb-4 pb-45"><Link to={redirectTO} className="btn btn-warning mt-3 mx-3"><FormattedMessage id="hr.start.ur.test" defaultMessage="Access to your tools"/></Link></div>
          )}  */}
        </div>
        {/* <div>{this.renderLoading()}</div> */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.auth.isSignedIn,
    lang: state.locale.lang
  };
};


CheckoutForm = connect(
  mapStateToProps,
  { updaetPaymentData }
)(CheckoutForm);

export default reduxForm({
  form: "CheckoutForm"
})(CheckoutForm);

// export default connect(
//   mapStateToProps,
//   { makeCharge }
// )(injectStripe(CheckoutForm));
